<template>
    <v-container fill-height>
        <div class="presentation-wrapper" id="presWrap">
            <div v-for="(product, index) in presentation.products"
                    class="presentation-product">
                <div class="image text-center">
                    <v-img
                            v-if="product.images"
                            class="white--text"
                            height="100"
                            width="175"
                            :src="product.album.files[0].url"
                    ></v-img>
                </div>
                <div class="content">
                    <div class="pres_title">
                        {{ product.name }}
                    </div>
                    <div class="info-list">
                        <strong>FOB: </strong> {{ product.fob_price +
                        ((product.fob_price / 100) * presentation.shop_percentage_increase) | currency }}
                        <br>

                        <strong>Material:</strong> {{ product.material }}
                        <br>
                        <strong>Item#:</strong> {{ product.item_number }}
                        <br>
                        <strong>Size:</strong> {{ product.item_size }}
                        <br>
                        <strong>Packing:</strong> {{ product.packaging }}
                        <br>
                        <strong>Updated: </strong> {{ product.updated_at | formatDate }}
                    </div>
                </div>
                <div v-if="index / 15 === 0 && index !== 0">
                    <div class="page-break"></div>
                    <div style="height:5px; width: 100%;"></div>
                </div>
            </div>
        </div>
        <div>
            <v-btn
                  color="blue-grey darken-4"
                  @click="download"
                >
                  Download
                </v-btn>
        </div>
    </v-container>
</template>
<style lang="css">
    .presentation-wrapper {
        width:1420px;
        margin:auto;
        overflow: auto;
    }
    .presentation-product {
        width: 175px;
        margin-bottom:15px;
        margin-right:5px;
        margin-left:5px;
        float:left;
        font-size:12px;
        font-family: Helvetica Neue, Helvetica, Arial;
        height:260px;
        background:white;
    }
    .text-center {
        text-align: center;
    }
    .pres_title {
        margin:0px;
        padding:5px;
        font-size: 11px !important;
        font-weight:bold;
    }
    .page-break {
        page-break-after: always;
    }

    .info-list {
        padding:5px;
        font-size: 12px;
    }
</style>
<script>
    import html2canvas from 'html2canvas'
    import jsPDF from 'jspdf'

    export default {
        components: {},
        data() {
            return {
                id: 0,
                presentation: {}
            }
        },
        created() {
            this.id = this.$route.params.id;
            this.load_presentation(this.id);
        },
        methods: {
            load_presentation(id) {
                this.$http.get(
                    this.$http.defaults.baseURL + '/admins/presentations/' + id + '/').then((response) => {
                    this.presentation = response.data;
                    // const image2base64 = require('image-to-base64');
                    let link = '';
                    let self = this;
                    this.presentation.products.forEach(function(o, i){
                        if (o.images.length)
                        {
                            link = product.album.files[0].url;
                            //TODO: sebastian to set images then replace data url with url
                            self.getBase64Image(link, function(myBase64) {
                                console.log(myBase64); // myBase64 is the base64 string
                            });
                        }
                    });
                    console.log(this.presentation);
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            getBase64Image(url, callback) {
                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        callback(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            },
            download()
            {
                const filename  = this.presentation.name+'.pdf';

                html2canvas(document.querySelector('#presWrap'),
                    {scale: 4}
                ).then(canvas => {
                    let pdf = new jsPDF('p', 'mm', 'a4');
                    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
                    pdf.save(filename);
                });
            }
        }
    }
</script>
